<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page" v-if="mode === 'canceled' || mode === 'creator' || mode === 'pending-for-delivery' || mode === 'incoming'">
                <div class="content__header-left">
                    <router-link to="/dispatch" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </router-link>
                    <div class="label-icon purple">
                        <inline-svg :src="require(`@/assets/img/Delivery-Truck-Fast.svg`)" />
                    </div>
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">{{ dispatch.dispatchID }}</h1>

                        <ul class="heading-list" v-if="mode === 'canceled'">
                            <li>
                                <inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
                                {{ $translate('canceled') }}
                            </li>
                            <li>
                                {{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}
                            </li>
                        </ul>

                        <ul class="heading-list" v-if="mode === 'creator'">
                            <li style="text-transform: capitalize;">
                                <inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
                                {{dispatch.status}}
                            </li>
                            <li>
                                {{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}
                            </li>
                        </ul>

                        <!-- <ul class="heading-list" v-if="mode === 'pending-for-approval'">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" />
                                Pending for Approval
                            </li>
                            <li>{{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}</li>
                        </ul> -->
                        <ul class="heading-list" v-if="mode === 'pending-for-delivery'">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" />
                                {{ $translate('pending-for-delivery') }}
                            </li>
                            <li>{{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}</li>
                        </ul>

                        <ul class="heading-list" v-if="mode === 'incoming'">
                            <li>
								<inline-svg :src="require(`@/assets/img/ingoing-icon.svg`)" />
                                {{ $translate('ingoing') }}
                            </li>
                            <li>{{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}</li>
                        </ul>
                    </div>
                </div>

                <div class="content__header-right" v-if="mode === 'creator'">
                    <button @click="innerPageMode = 'cancel'" class="btn btn-w-shadow btn-standart red-color">
                        <span>{{ $translate('cancel-a-dispatch') }}</span>
                    </button>
                    <button @click="openQrCode('dispatch')" type="button" class="btn btn-w-shadow btn-standart" data-target="qr-code">
                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                        <span>{{ $translate('qr-code') }}</span>
                    </button>
                    <button v-if="canSendDispatch" type="button" class="btn btn-w-shadow btn-standart" @click="sendDispatch">
                        <inline-svg :src="require(`@/assets/img/transportations.svg`)" />
                        <span>{{ $translate('send-dispatch') }}</span>
                    </button>
                    <router-link
                        v-if="dispatch && (dispatch.status !== 'completed' && dispatch.status !== 'returned')"
                        :to="`/dispatch/modify/${id}`" type="button" class="btn btn-primary btn-standart"
                    >
                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                        <span>{{ $translate('edit') }}</span>
                    </router-link>
                </div>

                <!-- <div class="content__header-right" v-if="mode === 'pending-for-approval'">
                    <button @click="openQrCode('dispatch')" type="button" class="btn btn-w-shadow btn-standart" data-target="qr-code">
                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                        <span>QR Code</span>
                    </button>
                    <BluePlusButton @click="approveDispatch">Approve</BluePlusButton>
                    <button type="button" class="btn btn-w-shadow btn-standart red-color" data-target="reject-dispatch" @click="openRejectDispatchPopup">
                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                        <span>Reject</span>
                    </button>
                </div> -->

                <div class="content__header-right" v-if="mode === 'incoming'">
                    <button @click="openQrCode('dispatch')" type="button" class="btn btn-w-shadow btn-standart" data-target="qr-code">
                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                        <span>{{ $translate('qr-code') }}</span>
                    </button>
                    <button @click="innerPageMode='return'" type="button" class="btn btn-w-shadow btn-standart">
						<inline-svg :src="require(`@/assets/img/return-dispatch-icon.svg`)" />
                        <span>{{ $translate('return-dispatch') }}</span>
                    </button>
                    <button @click="innerPageMode='receive'" type="button" class="btn btn-primary btn-standart">
						<inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
                        <span>{{ $translate('receive-dispatch') }}</span>
                    </button>
                </div>
            </div>

            <div class="content__header sub-page" v-if="(['Approved','Completed','Returned','Rejected']).includes(mode)">
                <div class="content__header-left">
                    <router-link to="/dispatch" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </router-link>
                    <div class="label-icon purple">
                        <!-- <img src="img/Delivery-Truck-Fast.svg" alt="" /> -->
                        <inline-svg :src="require(`@/assets/img/Delivery-Truck-Fast.svg`)" />
                    </div>
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">{{dispatch.dispatchID}}</h1>
                        <ul class="heading-list">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" />
                                {{mode}}
                            </li>
                            <li>{{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : "-" }}</li>
                        </ul>
                    </div>
                </div>
                <div class="content__header-right">
                    <button @click="openQrCode('dispatch')" type="button" class="btn btn-w-shadow btn-standart" data-target="qr-code">
                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                        <span>{{ $translate('qr-code') }}</span>
                    </button>
                </div>
            </div>
			
            <div class="headline" v-if="mode == 'cancel'">
                <h1 class="headline__title">{{ $translate('cancel-dispatch') }}</h1>
                <p class="headline__text">{{ $translate('check-all-dispatch-information-before-cancel-a-dispatch') }}</p>
            </div>

			<div class="headline" v-if="mode=='receive'">
				<h1 class="headline__title">{{ $translate('receive-dispatch') }}</h1>
				<p class="headline__text">{{ $translate('check-all-dispatch-information-before-confirm-receiving-dispatch') }}</p>
			</div>

			<div class="headline" v-if="mode=='return'">
				<h1 class="headline__title">{{ $translate('return-dispatch') }}</h1>
				<p class="headline__text">{{ $translate('check-all-dispatch-information-before-return-a-dispatch') }}</p>
			</div>
	
    		<div class="headline" v-if="mode=='view'">
				<h1 class="headline__title">{{ $translate('view-dispatch') }}</h1>
				<p class="headline__text">{{ $translate('check-all-dispatch-information-before-cancel-a-dispatch') }}</p>
			</div>

            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item" v-for="tab in pageTabs" :key="tab">                                      
                        <a data-tab="сompanies" class="nav-tab" @click="switchTab(tab)" :class="isActiveTab(tab)"> {{tabsName[tab]}} </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="track-dispatch" class="tab-content__item" :class="isActiveTab('track-dispatch')">
                        <TrackTheDispatch v-if="isActiveTab('track-dispatch')" />
                    </div>
                    <div id="dispatch-details" class="tab-content__item" :class="isActiveTab('dispatch-details')">
                        <div class="tab-content-wrapper" >
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> {{ $translate('dispatch-id') }} </span>
                                        <p>{{dispatch.dispatchID}}</p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('owner') }} </span>
                                        <p>{{ dispatch.owner && dispatch.owner.fullname ? dispatch.owner.fullname : '-'}}</p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('delivery-date') }} </span>
                                        <p>02.02.2021, 12:00</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> {{ $translate('po-number') }} </span>
                                        <p>PO-123456789</p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('invoice-number') }} </span>
                                        <p>12345</p>
                                    </li>
                                    <li>
                                        <span> SSCC </span>
                                        <p>(00) 0 0123456 000000001 8</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="сompanies" class="tab-content__item" :class="isActiveTab('companies')">
                        <DispatchCompanyTab v-if="isActiveTab('companies')" :companies="dispatch.companies" :companiesStatus='dispatch.companiesStatus' :globalStatus="getGlobalDispatchStatus(dispatch)"></DispatchCompanyTab>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <BatchesTab @openQrCode="openQrCode('dispatch')" :id="dispatch.id" :batches="dispatch.batches" v-if="isActiveTab('products')"></BatchesTab>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <ConditionsTab :conditions="dispatch.contract_conditions" v-if="isActiveTab('conditions')" :deviceDataDuringSC="true"></ConditionsTab>
                    </div>
                    <div id="documents" class="tab-content__item" :class="isActiveTab('documents')">
                        <DocumentsTab :documents="dispatch.documents" v-if="isActiveTab('documents')"></DocumentsTab>
                    </div>
                    <!-- <div id="rule-log" class="tab-content__item" :class="isActiveTab('rule-log')">
					<div class="filter-options">
						<div class="filter-options-wrapper flex flex-xxl-wrap flex-auto">
							<div class="filter-options__items flex-x-wrap flex-auto">
								<label class="datepicker-el">
									<input type="date" class="current-data">
								</label>
								<label class="datepicker-el">
									<input type="date" class="current-data">
								</label>
								<div class="categories time-piker dropdown-wrapper">
									<input type="time" class="time" />
								</div>
								<div class="categories dropdown-wrapper">
									<button type="button" class="btn btn-w-shadow btn-standart dropdown-btn">
										<span class="active-categories">Status</span>
										<span><img src="img/Arrow-down.svg"></span>
									</button>
									<div class="categories__dropdown dropdown-content">
										<ul>
											<li>
												<span>All</span>
											</li>
											<li>
												<span>Enabled</span>
											</li>
											<li>
												<span>Disabled</span>
											</li>
										</ul>
									</div>
								</div>
								<div class="categories dropdown-wrapper">
									<button type="button" class="btn btn-w-shadow btn-standart dropdown-btn">
										<span class="active-categories">Batch</span>
										<span><img src="img/Arrow-down.svg"></span>
									</button>
									<div class="categories__dropdown dropdown-content">
										<ul>
											<li>
												<span>All</span>
											</li>
											<li>
												<span>Enabled</span>
											</li>
											<li>
												<span>Disabled</span>
											</li>
										</ul>
									</div>
								</div>
								<div class="categories dropdown-wrapper">
									<button type="button" class="btn btn-w-shadow btn-standart dropdown-btn">
										<span class="active-categories">Product</span>
										<span><img src="img/Arrow-down.svg"></span>
									</button>
									<div class="categories__dropdown dropdown-content">
										<ul>
											<li>
												<span>All</span>
											</li>
											<li>
												<span>Enabled</span>
											</li>
											<li>
												<span>Disabled</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<button type="button" class="btn btn-w-shadow btn-standart-2">
                                <inline-svg :src="require(`@/assets/img/map-location-pin.svg`)" />
								<span>Map</span>
							</button>
						</div>
					</div>
					<div class="table-wrapper">
						<table class="table">
							<thead>
								<tr>
									<th>
										<div class="table-filter flex">
											<label class="module__check">
												<input type="checkbox" name="privacy_policy" checked="">
												<span class="check"></span>
											</label>
											<div class="table-filter__options flex-auto">
												<button class="table-filter__btn">
													<span>
														Date {{'&'}} Time
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Location
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Product
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Batch
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Temperature
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Battery
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Status
													</span>
												</button>
											</div>
										</div>
									</th>
									<th>
										<div class="table-filter">
											<div class="table-filter__options">
												<button class="table-filter__btn">
													<span>
														Alert
													</span>
												</button>
											</div>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr class="checked">
									<td>
										<label class="module__check">
											<input type="checkbox" name="privacy_policy" checked="">
											<span class="check"></span>
											<span class="text">14.05.2021, 13:40</span>
										</label>
									</td>
									<td>
										<div class="flex icon-text">
                                            <inline-svg :src="require(`@/assets/img/Pin-Location.svg`)" />

											<span>Italy, Rome</span>
										</div>
									</td>
									<td>
										Barolo Collaretto 2009
									</td>
									<td>
										123456789
									</td>
									<td>
										25.7°C
									</td>
									
									<td>
										58%
									</td>
									<td>
										Enabled
									</td>
									<td>
										-
									</td>
								</tr>
							
							</tbody>
						</table>
					</div>
				</div> -->
                </div>
            </div>
        </main>	
		<FooterTabsControl
			v-if="mode=='receive'"
			@cancelAction="innerPageMode=null"
			confirmName="Receive"
			@confirm="receiveDispatch"
			@swipeTab="swipeTab"
			:pageTabs="pageTabs"
			:activeTab="activeTab"
		/>
		<FooterTabsControl
			v-if="mode=='return'"
			@cancelAction="innerPageMode=null"
			confirmName="Return"
			@confirm="returnDispatch"
			@swipeTab="swipeTab"
			:pageTabs="pageTabs"
			:activeTab="activeTab"
		/>
		<FooterTabsControl
			v-if="mode=='cancel'"
			@cancelAction="innerPageMode=null"
			confirmName="Cancel Dispatch"
			@confirm="cancelAllDispatch"
			@swipeTab="swipeTab"
			:pageTabs="pageTabs"
			:activeTab="activeTab"
		/>


        <RejectDispatch v-if="rejectDispatchPopup" @close="closeRejectDispatchPopup" @reject="rejectDispatch" @modify="modifyDispatch" />
        <QrCode 
            v-if="qrCode.show" 
            :fullLink="qrCode.fullLink"  
            @close="closeQrCode"
        ></QrCode>
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Tabs from "@/components/tabs";
import Buttons from "@/components/buttons";
import TrackTheDispatch from "./TrackTheDispatch";
import { mixTabs } from "@/mixins";
import { mixDispatches } from "@/mixins/dispatch";

export default {
    name: "DispatchPage",
    props: ["id"],
    components: {
        ...Base,
        ...Popups,
        ...Tabs,
        ...Buttons,
        TrackTheDispatch,
    },
    mixins: [mixTabs, mixDispatches],
    data() {
        return {
            rejectDispatchPopup: false,
            qrCode: {
                show:false,
                fullLink:null
            },
            // pageTabs: ["companies", "products", "conditions", "documents", "rule-log"],
            tabsName:{
                'track-dispatch':"Track Dispatch",
                'companies':"Companies",
                "products":"Products",
                "conditions":"Conditions",
                "documents":"Documents",
                "rule-log":"Device Rule Log",
                "dispatch-details":"Dispatch Details"
            },
            innerPageMode:null,
        };
    },
    computed: {
        pageTabs(){
            let mode=this.mode
            if(mode=='cretor')
                return ["companies", "products", "conditions", "documents","rule-log"]
            if(mode=='pending-for-approval'||mode=='incoming')
                return ["companies", "products", "conditions", "documents", "track-dispatch"]
            if(mode=='cancel'||mode=='return')
                return ["dispatch-details", "products", "conditions", "documents"]
            if(mode=='receive')
                return ["dispatch-details", "products", "documents"]
            return ["companies", "products", "conditions", "documents", "track-dispatch"]
           
        },
        localStatus(){
            this.dispatch.companiesStatus.find(c=>c.id==this.id)||{}
        },
        dispatch(){
            return this.$store.state.activeDispatch||{}
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        mode() {
            if (!this.dispatch?.companiesStatus) return null;
            let status = this.dispatch.companiesStatus.find((c) => c.id == this.companyData.id)?.status || null;
            if(!status){
                return 'view'
            }
            let isCreator = this.isCreator(this.dispatch, this.companyData.id);
            if (isCreator) {
                if (this.innerPageMode=='cancel') {
                    return "cancel";
                }
                if (status=='canceled') {
                    return "canceled";
                }
                return "creator";
            } else {
                // if (status == "pending") {
                //     return "pending-for-approval";
                if (status == "pending") {
                    return "pending-for-delivery";
                } else if (status == "approved") {
                    return "Approved";
                } else if (status == "rejected") {
                    return "Rejected";
                } else if (status == "incoming") {
					if (this.innerPageMode=='receive') {
                    	return "receive";
					}
					if (this.innerPageMode=='return') {
                    	return "return";
					}
                    return "incoming";
                } else if (status == "completed") {
                    return "Completed";
                } else if (status == "returned") {
                    return "Returned";
                } else {
                    return 'view';
                }
            }
        },
        canSendDispatch() {
            if (this.dispatch.companiesStatus && this.dispatch.companiesStatus.length) {
                let isApproved = true;
                this.dispatch.companiesStatus.forEach((c) => {
                    if (c.type == "incoming") {
                        isApproved = isApproved && c.status == "pending";
                    }
                });
                if (this.dispatch.companies.length<2){
                    return false
                }
                return isApproved;
            }
        },
    },
    async created() {
        
    },
    async mounted(){
        this.$store.dispatch('GET_ACTIVE_DISPATCH',{id:this.id});
    },
    methods: {
        openQrCode(type){
            this.qrCode.show=true
            if(type==='dispatch'){
                this.qrCode.fullLink=`${window.location.origin}/qr-dispatch/${this.id}`
            }
        },
        closeQrCode(){
            this.qrCode.show=false
            this.qrCode.fullLink=null
        },

        openRejectDispatchPopup() {
            this.rejectDispatchPopup = true;
        },
        closeRejectDispatchPopup() {
            this.rejectDispatchPopup = false;
        },
        async rejectDispatch() {
            await this.changeCompanyStatus(this.id, this.companyData.id, "rejected");
            this.closeRejectDispatchPopup()
        },
        modifyDispatch() {
            this.$router.push(`/dispatch/modify/${this.id}?fromreject=true`);
        },
        
        // async sendDispatch() {
        //     for (let i of this.dispatch.companiesStatus) {
        //         if (i.type == "outcoming") {
        //             await this.changeCompanyStatus(this.dispatch.id, i.id, "outgoing");
        //         }
        //         if (i.type == "incoming") {
        //             await this.changeCompanyStatus(this.dispatch.id, i.id, "incoming");
        //         }
        //     }
        // },
        // async approveDispatch() {
        //     const options = { title: "Approve Dispatch?", size: "sm", okLabel: "Approve", cancelLabel: "Cancel" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             await this.changeCompanyStatus(this.id, this.companyData.id, "approved");
        //             // this.$router.push("/dispatch/"+this.id)
        //         }
        //     });
        // },
        // async receiveDispatch() {
        //     console.log(this.id);
        //     const options = { title: "Receive Dispatch?", size: "sm", okLabel: "Receive", cancelLabel: "Cancel" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             await this.changeCompanyStatus(this.id, this.companyData.id, "completed");
        //             // this.$router.push("/dispatch/"+this.id)
        //         }
        //     });
        // },
        // async returnDispatch() {
        //     const options = { title: "Return Dispatch?", size: "sm", okLabel: "Return", cancelLabel: "Cancel" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             await this.changeCompanyStatus(this.id, this.companyData.id, "returned");
        //             // this.$router.push("/dispatch/"+this.id)
        //         }
        //     });
        // },
        // async cancelAllDispatch() {
        //     const options = { title: "Cancel Dispatch?", size: "sm", okLabel: "Yes", cancelLabel: "No" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             for(let c of this.dispatch.companiesStatus){
        //                 this.changeCompanyStatus(this.id, c.id, "canceled");
        //             }
        //             // this.$router.push("/dispatch/"+this.id)
        //         }
        //     });
        // },

    },
};
</script>
